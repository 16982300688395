<template>
  <Breadcrumb class="app-breadcrumb" separator="/">
    <BreadcrumbItem v-for="(item, index) in levelList" :key="item.path">
      <span @click.prevent="handleLink(item, index)" :class="{ 'no-redirect': index == levelList.length - 1 }" class="breadcrumb-item">
        <svg-icon :type="item.meta.icon" v-if="item.meta && item.meta.icon" class="icon"></svg-icon>
        {{ item.meta.title }}
      </span>
    </BreadcrumbItem>
  </Breadcrumb>
</template>

<script>
import * as pathToRegexp from 'path-to-regexp'
import SvgIcon from '@/icons/SvgIcon.vue'

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'NavbarBreadcrumb',
  components: { SvgIcon },
  data() {
    return {
      levelList: [],
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb()
    },
  },
  mounted() {
    this.getBreadcrumb()
  },
  methods: {
    getBreadcrumb() {
      // only show routes with meta.title
      let matched = this.$route.matched.filter(item => item.meta && item.meta.title)
      const first = matched[0]

      if (!this.isDashboard(first)) {
        matched = [{ path: '/', meta: { title: '首页', name: 'home', icon: 'ivu-ios-home' } }].concat(matched)
      }

      this.levelList = matched.filter(item => item.meta && item.meta.title && item.meta.breadcrumb !== false)
      // this.levelList.push(this.$route)
    },
    isDashboard(route) {
      return route && route.name && route.name.trim().toLocaleLowerCase() === 'home'
    },
    pathCompile(path) {
      // To solve this problem https://github.com/PanJiaChen/vue-element-admin/issues/561
      const { params } = this.$route,
        toPath = pathToRegexp.compile(path)

      return toPath(params)
    },
    handleLink(item, index) {
      if (index + 1 === this.levelList.length) {
        return
      }

      const { redirect, path } = item

      if (redirect) {
        this.$router.push(redirect)
        return
      }
      this.$router.push(this.pathCompile(path))
    },
  },
}
</script>

<style lang="scss" scoped>
.app-breadcrumb {
  display: inline-block;
  font-size: 14px;
  line-height: 50px;
  margin-left: 8px;

  .no-redirect {
    color: #97a8be;
    cursor: text;
  }

  .breadcrumb-item {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .icon {
      margin: 0 5px;
    }
  }
}
</style>
