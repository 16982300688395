import request from '@/utils/request'

/**
 * 登录API
 * @param {Object} data 登录需要的对象，username 和 password
 * @returns 请求的结果，promise对象
 */
export function login(data) {
  return request({
    url: '/manage/account/login',
    method: 'post',
    data,
  })
}

export function loginUserInfo() {
  return request({
    url: '/manage/account/info',
    method: 'get',
  })
}

export function logout() {
  return request({
    url: '/manage/account/log-out',
    method: 'post',
  })
}

export function switchAccount(id) {
  const data = {
    id: id,
  }

  return request({
    url: '/manage/account/switch-account',
    method: 'post',
    data,
  })
}
/**
 * 修改个人信息API
 * @param {Object} data 登录需要的对象，username 和 password
 * @returns 请求的结果，promise对象
 */
export function updateUserInfo(data) {
  return request({
    url: '/user/user/update-myself',
    method: 'post',
    data,
  })
}
