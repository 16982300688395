<template>
  <!-- <span class="text-button" :class="'text-button__' + type" :style="{ color: color ? color : null, disabled: disabled ? 'disabled' : '' }" v-on="$listeners"> -->
  <Poptip v-if="confirm" confirm :title="confirm" @on-ok="onOk" transfer>
    <span :class="{ 'text-button-box': disabled, 'btn-clear': clear }">
      <span class="text-button" :class="disabled ? 'disabled' : '' + type ? 'text-button__' + type : ''" :style="{ color: color ? color : null }">
        <svg-icon :type="icon" v-color="color" class="icon" v-if="icon"></svg-icon>
        <slot></slot>
      </span>
    </span>
  </Poptip>
  <span :class="{ 'text-button-box': disabled, 'btn-clear': clear }" v-else>
    <span class="text-button" :class="disabled ? 'disabled' : '' + type ? 'text-button__' + type : ''" :style="{ color: color ? color : null }" v-bind="$attrs">
      <svg-icon :type="icon" v-color="color" class="icon" v-if="icon"></svg-icon>
      <slot></slot>
    </span>
  </span>
</template>
<script>
export default {
  props: {
    icon: String,
    color: String,
    type: String,
    clear: Boolean,
    confirm: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    onOk(e) {
      this.$emit('click', e)
    },
  },
}
</script>
<style lang="scss" scoped>
.text-button-box {
  .disabled {
    color: #c5c8ce;
    pointer-events: none !important;
  }
  cursor: not-allowed !important;
}
.text-button {
  color: #2d8cf0;
  padding: 5px;
  cursor: pointer;
  margin: 3px;
  display: inline-block;
  &__light-primary {
    color: #5cadff;
  }

  &__dark-primary {
    color: #2b85e4;
  }

  &__info {
    color: #2db7f5;
  }

  &__success {
    color: #19be6b;
  }
  &__warning {
    color: #ff9900;
  }

  &__error {
    color: #ed4014;
  }

  // &.disabled {
  //   color: #c5c8ce;
  //   // pointer-events: none !important;
  //   // cursor: not-allowed !important;
  // }
  .icon {
    margin-right: 3px;
  }
}

.btn-clear .text-button {
  padding: 0;
  margin: 0;
}
</style>
