import { createApp } from 'vue'
import Viewer from 'v-viewer'
import 'view-ui-plus/dist/styles/viewuiplus.css'
import App from './App.vue'
import store from './store'
import router from './router'
import ViewUIPlus from 'view-ui-plus'
import '@/styles/index.scss' // global css
import '@/icons' // icon
import '@/permission' // permission control
import style from './directives/style'

// 启动提示
// config.productionTip = process.env.NODE_ENV == "development";

const app = createApp(App)
const width = document.body.getBoundingClientRect().width
const directives = {
  left: style.left,
  top: style.top,
}

app.use(ViewUIPlus, {
  size: width <= 1200 ? 'small' : width > 2000 ? 'large' : 'default',
  transfer: true,
  // capture: false,
})

for (let key in directives) {
  app.directive(key, directives[key])
}

Viewer.setDefaults({
  Options: {
    title: false,
    url: 'data-source',
  },
})

app.use(Viewer)

app.use(store)
app.use(router)
// 全局注入组件
import TextButton from '@/components/Form/TextButton'
app.component('text-button', TextButton)
import SvgIcon from '@/icons/SvgIcon' // svg component
app.component('svg-icon', SvgIcon)

app.mount('#app')
