<template>
  <div>
    <component v-bind:is="'style'">:root { --sidebar-width: {{ sidebarWidth }}; }</component>
    <SidebarLogo :collapse="isCollapse" />
    <!-- <el-scrollbar wrap-class="scrollbar-wrapper">
      <el-menu :default-active="activeMenu" :collapse="isCollapse" :background-color="variables.menuBg"
        :text-color="variables.menuText" :unique-opened="uniqueOpened" :active-text-color="variables.menuActiveText"
        :collapse-transition="false" mode="vertical" :default-openeds="openeds">
        <sidebar-item v-for="route in routers" :key="route.path" :item="route" :base-path="route.path" />
      </el-menu>
    </el-scrollbar> -->
    <!-- <Scroll :on-reach-bottom="handleReachBottom"> -->
    <Menu mode="vertical" theme="dark" :active-name="activeMenu" accordion>
      <sidebar-sub-menu v-for="route in routers" :key="route.path" :item="route" />
    </Menu>
    <!-- </Scroll> -->
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import SidebarLogo from './SidebarLogo'
import SidebarSubMenu from './SidebarSubMenu'
import variables from '@/styles/variables.scss'

export default {
  components: { SidebarSubMenu, SidebarLogo },
  computed: {
    ...mapGetters(['sidebar', 'routes']),
    routers() {
      return this.routes
    },
    activeMenu() {
      const { meta, path } = this.$route

      // if set path, the sidebar will highlight the path you set
      if (meta.activeMenu) {
        return meta.activeMenu
      }
      return path
    },
    variables() {
      return variables
    },
    isCollapse() {
      return !this.sidebar.opened
    },
    sidebarWidth() {
      return this.$store.state.settings.sidebarWidth + 'px'
    },
  },
  methods: {
    handleReachBottom() {
      // todo something
      return
    },
  },
}
</script>

<style lang="scss" scoped>
.ivu-scroll-wrapper {
  height: calc(100vh - 64px);
}

.hideSidebar .ivu-menu {
  transition: all 0.2s ease-in-out;
  width: 64px !important;

  &::hover {
    width: var(--sidebar-width) !important;
  }
}
.openSidebar .ivu-menu {
  transition: all 0.2s ease-in-out;
  width: var(--sidebar-width) !important;
}
</style>
