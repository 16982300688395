import { createRouter, createWebHashHistory } from 'vue-router'

import AdminLayout from '@/components/AdminLayout'

// console.log(getCurrentInstance,getCurrentInstance.use(), 'vue')
// Vue.use(VueRouter)

/**
  {
    path: '/',
    name: 'home',
    component: AdminLayout,
    redirect: '/home',
    hidden: false,      // 是否在菜单栏显示
    meta: { title: '首页', ...},
    children: [
      {
        path: '/home',
        name: 'home',
        meta: {
          title: '首页',
          hidden: false, // 是否在 菜单栏 显示
          icon: 'svg-name'/'icon-type'  // icon
          breadcrumb: false           // 是否在 面包屑 显示
          activeMenu: '/example/list'  // 侧边栏 高亮菜单
          group:'',    // 如果菜单分组, 则需要此项
        },
        component: () => import('@/views/home'),
      },
    ],
  },
*/

export const routes = [
  {
    path: '/login',
    name: 'login',
    component: () => {
      return import('@/views/login/index')
    },
    meta: { title: '' },
    hidden: true,
  },
  {
    path: '/exception/:code',
    name: 'exception',
    hidden: true,
    meta: {},
    component: () => {
      return import('../views/Exception.vue')
    },
  },
]

export const allRoutes = [
  {
    path: '/',
    name: 'home',
    meta: { title: '大屏', icon: 'ivu-md-desktop' },
    component: AdminLayout,
    redirect: '/home',
    children: [
      {
        path: '/home',
        name: 'home',
        meta: { title: '大屏', icon: 'ivu-md-desktop' },
        component: () => {
          return import('@/views/home/index.vue')
        },
      },
    ],
  },
  {
    path: '/app',
    name: 'app',
    component: AdminLayout,
    redirect: '/app/entrance',
    children: [
      {
        path: '/app/entrance',
        name: 'app-entrance',
        meta: { title: 'APP首页设置', icon: 'ivu-md-appstore' },
        component: () => {
          return import('@/views/entrance/index.vue')
        },
      },
    ],
  },
  {
    path: '/machine',
    name: 'machine',
    component: AdminLayout,
    redirect: '/machine/index',
    children: [
      {
        path: '/machine/index',
        name: 'machine-index',
        meta: { title: '项目管理', icon: 'ivu-md-appstore' },
        component: () => {
          return import('@/views/machine/index.vue')
        },
      },
    ],
  },
  {
    path: '/manage',
    name: 'manage',
    component: AdminLayout,
    redirect: '/manage/users',
    children: [
      {
        path: '/manage/users',
        name: 'manage-users',
        meta: { title: '客户管理', icon: 'users' },
        component: () => {
          return import('@/views/system/account.vue')
        },
      },
    ],
  },
  {
    path: '/system',
    name: 'system',
    meta: { title: '系统设置', icon: 'ivu-ios-settings' },
    component: AdminLayout,
    children: [
      {
        path: '/user/password',
        name: 'user-password',
        meta: { title: '修改密码', icon: 'ivu-md-lock' },
        hidden: true,
        component: () => {
          return import('@/views/system/changePwd.vue')
        },
      },
      {
        path: '/user/userInfo',
        name: 'user-info',
        meta: { title: '个人资料', icon: 'cash-record' },
        hidden: true,
        component: () => {
          return import('@/views/system/userInfo.vue')
        },
      },
    ],
  },
  { path: '/:pathMatch(.*)*', redirect: '/exception/404', hidden: true },
]

const getRouter = () => {
  return createRouter({
    history: createWebHashHistory(),
    base: process.env.BASE_URL,
    scrollBehavior: () => {
      return { y: 0 }
    },
    routes,
  })
}

const router = getRouter()

export function resetRouter() {
  const newRouter = getRouter()

  router.matcher = newRouter.matcher // reset router
}

export function unLoginWhitesPath() {
  let paths = []

  for (let i = 0; i < routes.length; i++) {
    if (routes[i] && routes[i].path && routes[i].path) {
      paths.push(routes[i].path)
    }
  }
  return paths
}

export default router
