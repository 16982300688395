<template>
  <div v-if="item.hidden !== true">
    <Submenu
      :name="item.path"
      v-if="hasOneShowingChild(item)"
      class="submenu"
      ref="submenu"
    >
      <template v-slot:title>
        <svg-icon
          :type="item.meta.icon"
          v-if="item.meta && item.meta.icon"
          class="icon-menu icon-submenu"
        />
        <span class="submenu-title">{{ item.meta.title }}</span>
      </template>
      <slot></slot>
      <sidebar-sub-menu
        v-for="route in item.children"
        :key="route.name"
        :item="route"
      />
    </Submenu>
    <menu-item
      :name="onlyOneChild.path"
      :to="onlyOneChild.path"
      :target="onlyOneChild.target"
      class="sidebar-menu-item"
      v-else-if="onlyOneChild"
    >
      <svg-icon
        :type="onlyOneChild.meta.icon"
        v-if="onlyOneChild.meta.icon"
        class="icon-menu icon-menu-item"
      />
      <span class="menu-item-title">{{ onlyOneChild.meta.title }}</span>
      <slot></slot>
    </menu-item>
  </div>
</template>
<script>
import FixIOSBug from "./FixIOSBug.js";
import SvgIcon from "@/icons/SvgIcon.vue";

export default {
  name: "SidebarSubMenu",
  components: { SvgIcon },
  mixins: [FixIOSBug],
  props: {
    // route object
    item: {
      type: Object,
      required: true,
    },
    isNest: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    styleVar() {
      return {
        "--sidebar-width": this.$store.state.settings.sidebarWidth + "px",
      };
    },
  },
  data() {
    return {
      onlyOneChild: null,
    };
  },
  methods: {
    hasOneShowingChild(menu) {
      if (!menu || !menu.children || menu.children.length === 0) {
        this.onlyOneChild = menu;
        return false;
      }
      const showingChildren = menu.children.filter((item) => {
        if (item.hidden === true) {
          return false;
        }
        // Temp set(will be used if only has one showing child)
        this.onlyOneChild = item;
        // console.log(this.onlyOneChild, 'onlyOneChild')
        return true;
      });

      // When there is only one child router, the child router is displayed by default
      return showingChildren.length > 1;
    },
  },
};
</script>
<style scoped lang="scss">
.icon-menu {
  margin-right: 5px;
}

.hideSidebar {
  .submenu,
  .ivu-menu > div > .sidebar-menu-item {
    ::v-deep .ivu-menu-item.ivu-menu-item-active.ivu-menu-item-selected {
      padding-left: 40px !important;
    }

    .submenu-title,
    .menu-item-title,
    ::v-deep
      .ivu-menu-submenu-title
      .ivu-icon.ivu-icon-ios-arrow-down:last-child {
      display: none;
    }

    .icon-menu {
      font-size: 22px;
      margin-left: -4px;
      min-width: 20px;
    }

    &:hover {
      width: var(--sidebar-width) !important;

      .menu-item-title,
      .submenu-title,
      ::v-deep
        .ivu-menu-submenu-title
        .ivu-icon.ivu-icon-ios-arrow-down:last-child {
        display: initial !important;
      }
      .icon-menu {
        font-size: inherit !important;
        margin-left: 0 !important;
      }
    }
  }
}

.ivu-menu-item {
  display: flex;
  align-items: center;
}
</style>
