module.exports = {
  title: '华础机械',

  description: '',

  copyright: '2022 常州华础机电工程有限公司',

  name: 'hcjx',

  logo: '/admin-view/logo.png',

  showSettings: false,

  showLogo: true,

  /**
   * @type {boolean} true | false
   * @description Whether show the logo in sidebar
   */
  sidebarLogo: true,

  sidebarWidth: 160,

  navbarHeight: 54,
  footerHeight: 41,

  /**
   * @description 附件域名
   */
  attachDomain: 'http://image.dev.bdcpt.cn/',

  attachDomainPrivate: 'http://pimage.dev.bdcpt.cn/',
}
