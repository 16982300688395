<template>
  <div class="navbar shadow">
    <svg-icon
      type="navbar-hamburger"
      size="20"
      class="icon left"
      :class="{ 'rotate-x': sidebar.opened }"
      @click="toggleSideBar" />
    <svg-icon type="ivu-ios-arrow-back" size="20" @click="goBack" class="icon left" />
    <svg-icon type="ivu-ios-refresh" size="23" @click="refresh" class="icon right" />
    <navbar-breadcrumb class="breadcrumb-container" />
    <div class="navbar-title">
      <slot name="title"></slot>
    </div>
    <div class="navbar-right">
      <Dropdown style="margin-right: 20px" transfer stop-propagation>
        <div class="avatar-wrapper"> {{ name }}
          <Avatar
            :src="avatar != '' ? avatar : logoUrl()"
            class="shadow user-avatar"
            size="large" />
          <Icon type="ios-arrow-down"></Icon>
        </div>
        <template v-slot:list>
          <DropdownMenu>
            <router-link to="/user/userInfo">
              <DropdownItem>个人资料</DropdownItem>
            </router-link>
            <router-link to="/user/password">
              <DropdownItem>修改密码</DropdownItem>
            </router-link>
            <DropdownItem @click.enter="logout">退出登陆</DropdownItem>
          </DropdownMenu>
        </template>
      </Dropdown>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import NavbarBreadcrumb from "./NavbarBreadcrumb";
import defaultSettings from "@/settings";

export default {
  name: "NavBar",
  components: { NavbarBreadcrumb },
  inject: ["reload"],
  computed: {
    ...mapGetters(["sidebar", "name", "avatar"]),
    isAdmin() {
      return this.$store.state.user.level <= 1;
    },
  },
  data() {
    return {
      toggleId: null,
    };
  },
  mounted() { },
  methods: {
    logoUrl() {
      return defaultSettings.logo;
    },
    toggleSideBar() {
      this.$store.dispatch("app/toggleSideBar");
    },
    async logout() {
      await this.$store.dispatch("user/logout");
      this.$router.push(`/login?redirect=${this.$route.fullPath}`);
    },
    refresh() {
      // Todo something
      this.reload();
    },
    goBack() {
      window.history.back();
    },
  },
};
</script>
<style lang="scss" scoped>
.navbar {
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;
  background: #fff;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  .hamburger-container {
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .icon {
    padding: 0 10px;
    width: calc(1em + 20px);
    height: 100%;
    line-height: 100%;
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
    &.ivu-icon {
      display: flex;
      align-items: center;
    }
  }

  .navbar-title {
    flex: 1;
  }

  .navbar-right {
    display: flex;
    align-items: center;
  }
  .avatar-wrapper {
    padding: 0 10px;
    cursor: pointer;
    display: flex;
    align-items: center;

    .user-avatar {
      margin: 0 10px;
    }

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }
}
</style>
